<script>
import {
  configureChains,
  connect,
  createConfig,
  disconnect,
  getAccount,
} from "@wagmi/core";
import { mainnet } from "@wagmi/core/chains";
import { CoinbaseWalletConnector } from "@wagmi/connectors/coinbaseWallet";
import { publicProvider } from "@wagmi/core/providers/public";
import { addPayExternalAccount } from "~/services/externalAccounts";

export default {
  methods: {
    async connectCoinbase() {
      const { chains, publicClient, webSocketPublicClient } = configureChains(
        [mainnet],
        [publicProvider()]
      );

      createConfig({
        autoConnect: false,
        publicClient,
        webSocketPublicClient,
      });
      const cbConnector = new CoinbaseWalletConnector({
        chains,
        options: {
          appName: "Riseworks",
        },
      });
      await disconnect();
      await connect({ connector: cbConnector });
      const account = await getAccount();

      const data = {
        name: "Coinbase Wallet",
        type: "eth_address",
        data: { address: account.address },
        logo: "/icons/coinbase.png",
        token: "USDC",
        privatelyOwned: false,
        exchangeWallet: true,
      };
      try {
        this.$loader.show();
        const recaptchaToken = await this.$recaptcha("pay_external_account");
        const createdExternalAccount = await addPayExternalAccount(
          data,
          recaptchaToken
        );
        this.$loader.hide();
        this.$toastSuccess(`Coinbase address added successfully.`);
        this.$router.push(`/withdraw/blockchain/${createdExternalAccount.id}`);
      } catch (e) {
        this.$loader.hide();
        this.$sentryCaptureException(e);
      }
    },
  },
};
</script>

<template>
  <div class="mx-auto max-w-[640px] pb-40 text-gray-800" @submit="onSubmit">
    <div class="mb-7 text-2xl font-bold">Coinbase Wallet</div>
    <div class="mb-7">
      <div class="mb-2 text-sm font-semibold">What is Coinbase Wallet?</div>
      <div class="text-sm">
        Coinbase Wallet is a mobile app that allows you to buy, sell, and trade
        cryptocurrencies. If you already have an account at Coinbase Wallet, you
        can connect it to your Rise account to easily transfer funds to Coinbase
        Wallet after payment. To find out more, go to
        <a
          href="https://www.coinbase.com/wallet"
          target="_blank"
          class="underline"
        >
          https://www.coinbase.com/wallet </a
        >.
      </div>
    </div>
    <RiseButton text="Connect to Coinbase Wallet" @click="connectCoinbase" />
  </div>
</template>
